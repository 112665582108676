<template>
    <div class="logout">
      <p>Logging out...</p>
    </div>
  </template>
  
  <script setup>
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  
  const store = useStore();
  const router = useRouter();
  
  store.dispatch('logout').then(() => { // looks up the logout action in the store/index.js
    router.push('/');
  });
  </script>
  
  <style scoped>
  .logout {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-size: 1.5rem;
  }
  </style>
  