<template>
  <div v-if="hospitals.length > 1">
    <label for="hospital-select">Select Hospital:</label>
    <select id="hospital-select" v-model="selectedHospital" @change="onHospitalChange">
      <option v-for="hospital in hospitals" :key="hospital.id" :value="hospital">
        {{ hospital.name }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import api from '../axios';

const store = useStore();
const selectedHospital = ref(store.getters.selectedHospital);
const hospitals = ref([]);

const user = computed(() => store.getters.user);

const fetchHospitals = async () => {
  try {
    if (user.value.accessRole === 'admin') {
      // Fetch all hospitals if the user is an admin
      const response = await api.get('/admin/hospitals');
      hospitals.value = response.data.map(hospital => ({
        id: hospital._id,
        name: hospital.name,
      }));
    } else if (user.value.accessRole === 'hospital') {
      // Fetch hospitals assigned to the hospital user
      const response = await api.get('/users/hospitals');
      hospitals.value = response.data.map(hospital => ({
        id: hospital._id,
        name: hospital.name,
      }));

      // If there is only one hospital, set it as the selected hospital
      if (hospitals.value.length === 1) {
        selectedHospital.value = hospitals.value[0];
        store.dispatch('selectHospital', selectedHospital.value); // Update the store
      }
    }
  } catch (error) {
    console.error('Error fetching hospitals:', error);
  }
};

const onHospitalChange = () => {
  store.dispatch('selectHospital', selectedHospital.value);
};

// Watch for changes in the Vuex store and update the local state
watch(
  () => store.getters.selectedHospital,
  (newSelectedHospital) => {
    selectedHospital.value = newSelectedHospital;
  }
);

onMounted(() => {
  fetchHospitals();
});
</script>

<style scoped>
#hospital-select {
  margin-left: 10px;
}
</style>
