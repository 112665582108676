<template>
  <div class="register-page">
    <Header />
    <main>
      <h1>Register</h1>
      <form @submit.prevent="register">
        <div class="form-group">
          <label for="name">Name</label>
          <input v-model="name" type="text" id="name" placeholder="Name" required />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input v-model="email" type="email" id="email" placeholder="Email" required />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input v-model="password" type="password" id="password" placeholder="Password" required />
        </div>
        <button type="submit" :disabled="isLoading">{{ isLoading ? 'Registering...' : 'Register' }}</button>
      </form>
      <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </main>
    <Footer />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Header from '../components/HeaderStd.vue';
import Footer from '../components/FooterStd.vue';

const name = ref('');
const email = ref('');
const password = ref('');
const isLoading = ref(false);
const successMessage = ref('');
const errorMessage = ref('');

const store = useStore();
const router = useRouter();

const register = async () => {
  isLoading.value = true;
  successMessage.value = '';
  errorMessage.value = '';
  try {
    await store.dispatch('register', { name: name.value, email: email.value, password: password.value });
    successMessage.value = 'Registration successful!';
    setTimeout(() => {
      router.push('/');
    }, 2000);
  } catch (error) {
    errorMessage.value = error.response?.data?.message || 'Registration failed';
    console.error('Registration failed:', error.message);
  } finally {
    isLoading.value = false;
  }
};
</script>
