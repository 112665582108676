import axios from 'axios';
import store from './store';
import { jwtDecode } from 'jwt-decode';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
});

api.interceptors.request.use(
    async (config) => {
        store.commit('setLoading', true); // Set loading to true
        let token = store.state.token;
        if (token) {
            let decodedToken;
            try {
                decodedToken = jwtDecode(token);
            } catch (error) {
                console.error('Error decoding token:', error);
                store.commit('logout');
                return config;
            }

            const now = Date.now() / 1000;

            if (decodedToken.exp - now < 3600) {
                try {
                    const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/auth/refresh-token`, {
                        token: store.state.token,
                    });
                    token = response.data.token;
                    store.commit('setToken', token);
                    const user = jwtDecode(token);
                    store.commit('setUser', user);
                } catch (error) {
                    console.error('Error refreshing token:', error);
                    store.commit('logout');
                }
            }
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        store.commit('setLoading', false); // Set loading to false on error
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        store.commit('setLoading', false); // Set loading to false on response
        return response;
    },
    (error) => {
        store.commit('setLoading', false); // Set loading to false on error
        return Promise.reject(error);
    }
);

export default api;
