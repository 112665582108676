<template>
  <footer>
    <div class="footer-content">
      <a href="/privacy-policy">Privacy Policy</a>
    </div>
  </footer>
</template>

<style scoped>
footer {
  padding: 0.2rem;
  background-color: #333;
  color: white;
  text-align: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
}
.footer-content a {
  color: white;
  text-decoration: none;
  font-size: 0.8rem;
}
</style>
