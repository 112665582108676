import { createRouter, createWebHistory } from 'vue-router';
import StartPage from '../views/StartPage.vue';
import UserLogin from '../views/UserLogin.vue'; // Import Login component
import UserRegister from '../views/UserRegister.vue'; // Import Register component
import UserLogout from '../views/UserLogout.vue';
import HospitalOverview from '../views/HospitalOverview.vue'; // Import HospitalView component
import HospitalNewShift from '../views/HospitalNewShift.vue'; // Import HospitalView component
import WorkerOverview from '../views/WorkerOverview.vue'; // Import WorkerView component
import AccountOverview from '../views/AccountOverview.vue'; // Import AccountView component
import AdminView from '../views/AdminView.vue'; // Import AdminView component
import SetupPasswordView from '../views/SetupPasswordView.vue';
import ShiftOverview from '../views/ShiftOverview.vue'; // Import ShiftOverview component
import store from '../store'; // Import the store to access authentication state

const routes = [
  {
    path: '/',
    name: 'Home',
    component: StartPage // Use HelloWorld component for the home route
  },
  {
    path: '/login',
    name: 'Login',
    component: UserLogin, // Use Login component for the login route
    meta: { guest: true } // This route is accessible only to guests (not logged in)
  },
  {
    path: '/register',
    name: 'Register',
    component: UserRegister, // Use Register component for the register route
    meta: { guest: true } // This route is accessible only to guests (not logged in)
  },
  {
    path: '/logout',
    name: 'Logout',
    component: UserLogout, // Use Logout component for the logout route
  },
  {
    path: '/hospital',
    name: 'Hospital',
    component: HospitalOverview, // Use HospitalView component for the hospital route
    meta: { requiresAuth: true, roles: ['admin', 'hospital'] } // This route requires authentication and hospital accessRole
  },
  {
    path: '/hospital/new-shift',
    name: 'Hospital New Shift',
    component: HospitalNewShift,
    meta: { requiresAuth: true, roles: ['admin', 'hospital'] } // This route requires authentication and hospital accessRole
  },
  {
    path: '/worker/overview',
    name: 'Worker Overview',
    component: WorkerOverview, 
    meta: { requiresAuth: true, roles: ['admin', 'worker'] } // This route requires authentication and hospital accessRole
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountOverview, 
    meta: { requiresAuth: true, roles: ['admin', 'worker', 'hospital'] } // This route requires authentication and hospital accessRole
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView, // Use AdminView component for the admin route
    meta: { requiresAuth: true, accessRole: 'admin' } // This route requires authentication and admin accessRole
  },
  {
    path: '/setup-password',
    name: 'SetupPassword',
    component: SetupPasswordView,
  },
  {
    path: '/shift-overview',
    name: 'ShiftOverview',
    component: ShiftOverview
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Add a global navigation guard to check for authentication and accessRole
router.beforeEach((to, from, next) => {
  const { requiresAuth, roles } = to.meta;

  if (requiresAuth) {
    if (!store.getters.isAuthenticated) {
      next({ name: 'Login' });
    } else if (roles && !roles.includes(store.getters.user.accessRole)) {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else if (to.meta.guest && store.getters.isAuthenticated) {
    next({ name: 'Home' });
  } else {
    next();
  }
});


export default router;
