import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import tokenRefresh from './plugins/tokenRefresh'; // Import the plugin
import './assets/styles.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.css';

library.add(fas, faEdit, faCheck);

const app = createApp(App);

app.use(router);
app.use(store);
app.use(tokenRefresh); // Use the plugin
app.component('font-awesome-icon', FontAwesomeIcon);
app.mount('#app');
