<template>
  <div class="hospital-page">
    <Header />
    <main>
      <!-- Include Hospital Selector -->
      <HospitalSelector v-if="user.accessRole === 'hospital' || user.accessRole === 'admin'" />

      <h1>Spital Neue Schicht Ausschreiben</h1>
      <div class="button-grid">
        <button v-for="button in buttons" :key="button" :class="{ selected: selectedButton === button }"
          @click="selectButton(button)">
          {{ button }}
        </button>
      </div>
      <div class="datetimepickers-row">
        <div class="datetimepicker-container">
          <label for="start-datetime">Start Datum & Zeit:</label>
          <flat-pickr v-model="startDate" :config="datetimePickerConfig" id="start-datetime"
            @update:model-value="calculateDates" />
        </div>
        <div class="datetimepicker-container">
          <label for="end-datetime">Ende Datum & Zeit:</label>
          <flat-pickr v-model="endDate" :config="datetimePickerConfig" id="end-datetime" />
        </div>
      </div>
      <div class="datetimepickers-row">
        <div class="datetimepicker-container full-width">
          <label for="shift-open-until">Schicht Offen Bis:</label>
          <flat-pickr v-model="shiftOpenUntil" :config="datetimePickerConfig" id="shift-open-until" />
        </div>
        <div class="datetimepicker-container full-width">
          <label>Schicht Dauer:</label>
          <div class="duration-input">
            <input type="number" v-model="durationHours" min="0" max="24" @change="updateEndDateFromDuration"
              :disabled="!startDate">h
            <input type="number" v-model="durationMinutes" min="0" max="59" @change="updateEndDateFromDuration"
              :disabled="!startDate">min
          </div>
        </div>
      </div>
      <div class="comment-container">
        <label for="comment">Kommentar (optional):</label>
        <textarea v-model="comment" id="comment" placeholder="Optionaler Kommentar zur Schicht"></textarea>
      </div>
      <h2 class="zusammenfassung">Zusammenfassung</h2>
      <div class="info-table">
        <div class="table-row table-header">
          <div class="table-cell">Datum & Zeit Start</div>
          <div class="table-cell">Datum & Zeit Ende</div>
          <div class="table-cell">Funktion</div>
          <div class="table-cell">Schicht Offen Bis</div>
        </div>
        <div class="table-row">
          <div class="table-cell">{{ formatDateTime(startDate) }}</div>
          <div class="table-cell">{{ formatDateTime(endDate) }}</div>
          <div class="table-cell">{{ selectedButton }}</div>
          <div class="table-cell">{{ formatDateTime(shiftOpenUntil) }}</div>
        </div>
      </div>
      <div class="action-buttons">
        <button @click="validateAndShowConfirmation" class="big-button">Schicht ausschreiben</button>
      </div>
    </main>
    <Footer />

    <!-- Custom Confirmation Modal -->
    <div v-if="showConfirmationModal" class="modal">
      <div class="modal-content">
        <h3>Bestätigung</h3>
        <p>Schicht kann nicht geändert werden. Möchten Sie fortfahren?</p>
        <div class="info-table">
          <div class="table-row table-header">
            <div class="table-cell">Datum & Zeit Start</div>
            <div class="table-cell">Datum & Zeit Ende</div>
            <div class="table-cell">Funktion</div>
            <div class="table-cell">Schicht Offen Bis</div>
          </div>
          <div class="table-row">
            <div class="table-cell">{{ formatDateTime(startDate) }}</div>
            <div class="table-cell">{{ formatDateTime(endDate) }}</div>
            <div class="table-cell">{{ selectedButton }}</div>
            <div class="table-cell">{{ formatDateTime(shiftOpenUntil) }}</div>
          </div>
        </div>
        <div class="modal-actions">
          <button @click="confirmSchichtAusschreiben">Ja</button>
          <button @click="showConfirmationModal = false">Nein</button>
        </div>
      </div>
    </div>

    <!-- Custom Error Modal -->
    <div v-if="showErrorModal" class="modal">
      <div class="modal-content">
        <h3>Fehler</h3>
        <p>{{ errorMessage }}</p>
        <div class="modal-actions">
          <button @click="showErrorModal = false">OK</button>
        </div>
      </div>
    </div>

    <!-- Success Message -->
    <div v-if="showSuccessMessage" class="success-message">
      Schicht erfolgreich ausgeschrieben
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import Header from '../components/HeaderStd.vue';
import Footer from '../components/FooterStd.vue';
import HospitalSelector from '../components/HospitalSelector.vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import api from '../axios';

const buttons = ref([]);
const selectedButton = ref(null);
const startDate = ref('');
const endDate = ref('');
const shiftOpenUntil = ref('');
const comment = ref('');
const showConfirmationModal = ref(false);
const showErrorModal = ref(false);
const errorMessage = ref('');
const showSuccessMessage = ref(false);
const durationHours = ref('');
const durationMinutes = ref('');

const store = useStore();
const user = computed(() => store.getters.user);
const selectedHospital = computed(() => store.getters.selectedHospital);

const datetimePickerConfig = {
  enableTime: true,
  dateFormat: 'Y-m-d H:i',
};

const formatDateTime = (dateTime) => {
  if (!dateTime) return '';
  const options = { weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return new Intl.DateTimeFormat('de-DE', options).format(new Date(dateTime));
};

const calculateDates = () => {
  if (startDate.value) {
    durationHours.value = 8;
    durationMinutes.value = 30;
    const start = new Date(startDate.value);

    // Calculate end date using current duration values
    const end = new Date(start);
    end.setHours(end.getHours() + parseInt(durationHours.value));
    end.setMinutes(end.getMinutes() + parseInt(durationMinutes.value));
    endDate.value = end.toISOString();

    // Calculate shift open until (start - 24 hours)
    const openUntil = new Date(start);
    openUntil.setHours(openUntil.getHours() - 24);
    shiftOpenUntil.value = openUntil.toISOString();
  } else {
    durationHours.value = '';
    durationMinutes.value = '';
    endDate.value = '';
    shiftOpenUntil.value = '';
  }
};

const updateEndDateFromDuration = () => {
  if (startDate.value) {
    const start = new Date(startDate.value);
    const end = new Date(start);
    end.setHours(end.getHours() + parseInt(durationHours.value));
    end.setMinutes(end.getMinutes() + parseInt(durationMinutes.value));
    endDate.value = end.toISOString();
  }
};

const selectButton = (button) => {
  selectedButton.value = button;
};

const validateAndShowConfirmation = () => {
  if (!selectedHospital.value || !selectedHospital.value.id) {
    errorMessage.value = 'Bitte wählen Sie ein Krankenhaus aus.';
    showErrorModal.value = true;
    return;
  }
  if (!selectedButton.value || !startDate.value || !endDate.value || !shiftOpenUntil.value) {
    errorMessage.value = 'Bitte füllen Sie alle erforderlichen Felder aus.';
    showErrorModal.value = true;
    return;
  }

  showConfirmationModal.value = true;
};

const confirmSchichtAusschreiben = async () => {
  showConfirmationModal.value = false;

  try {
    await api.post('/shifts', {
      hospitalId: selectedHospital.value.id,
      type: selectedButton.value,
      start: startDate.value,
      end: endDate.value,
      shiftOpenUntil: shiftOpenUntil.value,
      comment: comment.value
    });
    showSuccessMessage.value = true;
    setTimeout(() => {
      showSuccessMessage.value = false;
    }, 5000);
  } catch (error) {
    console.error('Error creating shift:', error);
    alert('Fehler beim Ausschreiben der Schicht');
  }
};

onMounted(async () => {
  try {
    const response = await api.get('/users/roles');
    buttons.value = response.data.filter(role => role !== 'admin' && role !== 'hospital');
  } catch (error) {
    console.error('Error fetching roles:', error);
  }
});
</script>

<style scoped>
.hospital-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
}

h1 {
  margin-bottom: 1rem;
}

.zusammenfassung {
  margin-top: 3rem;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1rem;
}

button {
  padding: 0.75rem 1.5rem;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  box-sizing: border-box;
}

button:hover {
  background-color: #555555;
}

button.selected {
  background-color: #555555;
  /* Keep the selected color consistent */
}

.big-button {
  padding: 1rem 2rem;
  font-size: 1.25rem;
}

.datetimepickers-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.datetimepicker-container {
  margin-bottom: 1rem;
  width: 48%;
}

.datetimepicker-container.full-width {
  width: 48%;
  margin-bottom: 1rem;
}

.datetimepicker-container label {
  display: block;
  margin-bottom: 0.5rem;
}

.comment-container {
  width: 100%;
  margin-bottom: 1rem;
}

.comment-container label {
  display: block;
  margin-bottom: 0.5rem;
}

.comment-container textarea {
  width: 100%;
  height: 100px;
  padding: 0.5rem;
  box-sizing: border-box;
  resize: vertical;
}

.info-table {
  display: table;
  width: 100%;
  margin: 1rem 0;
  border-collapse: collapse;
}

.table-row {
  display: table-row;
}

.table-header .table-cell {
  font-weight: bold;
}

.table-cell {
  display: table-cell;
  padding: 0.5rem;
  border: 0px solid #ddd;
}

.action-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 4px;
  text-align: center;
}

.modal-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.modal-actions button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: #007aff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-actions button:hover {
  background-color: #005bb5;
}

.success-message {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.duration-input {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.duration-input input {
  width: 60px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

input,
textarea,
select {
  font-size: 16px;
  /* Minimum font size to prevent zoom on iOS */
}

.duration-input input {
  width: 60px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}
</style>
