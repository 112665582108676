<template>
  <div class="account-management-page">
    <Header />
    <main>
      <h1>Account Management</h1>
      <form @submit.prevent="updateAccount">
        <div class="form-group">
          <label for="name">Name</label>
          <input v-model="name" type="text" id="name" placeholder="Name" required />
        </div>
        <div class="form-group">
          <label for="phone">Mobile</label>
          <vue-tel-input v-model="phone" required />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input 
            v-model="email" 
            type="email" 
            id="email" 
            placeholder="Email" 
            required
            :class="{ 'invalid': email && !isEmailValid }" 
          />
          <span v-if="email && !isEmailValid" class="error-message">Bitte gültige Email Adresse eingeben</span>
        </div>
        <div class="form-group">
          <label>Notifications</label>
          <div>
            <input type="checkbox" id="sms" v-model="notificationPreferences.sms" />
            <label for="sms">SMS</label>
          </div>
          <div>
            <input type="checkbox" id="emailNotifications" v-model="notificationPreferences.email" />
            <label for="emailNotifications">Email</label>
          </div>
        </div>
        <div class="form-group">
          <label for="notificationRole">Ausbildung</label>
          <input v-model="notificationRole" type="text" id="notificationRole" placeholder="Notification Role" disabled />
        </div>
        <div class="form-group">
          <label for="currentPassword">Current Password</label>
          <input v-model="currentPassword" type="password" id="currentPassword" placeholder="Current Password" />
        </div>
        <div class="form-group">
          <label for="newPassword">New Password</label>
          <input v-model="newPassword" type="password" id="newPassword" placeholder="New Password" />
        </div>
        <button type="submit">Update</button>
        <p v-if="message" :class="['message', messageType === 'error' ? 'message-error' : 'message-success']">{{ message }}</p>
      </form>
    </main>
    <Footer />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import Header from '../components/HeaderStd.vue';
import Footer from '../components/FooterStd.vue';
import api from '../axios';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

const name = ref('');
const phone = ref('');
const email = ref('');
const notificationRole = ref('');
const messageType = ref('');
const currentPassword = ref('');
const newPassword = ref('');
const notificationPreferences = ref({
  sms: false,
  email: false,
});
const message = ref('');

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const isEmailValid = computed(() => emailRegex.test(email.value));

const store = useStore();

const fetchAccountData = async () => {
  try {
    const response = await api.get('/account', {
      headers: {
        Authorization: `Bearer ${store.state.token}`,
      },
    });
    const user = response.data;
    name.value = user.name;
    phone.value = user.phone;
    email.value = user.email;
    notificationRole.value = user.notificationRole;
    notificationPreferences.value = user.notificationPreferences;
  } catch (error) {
    console.error('Failed to fetch account data:', error.message);
  }
};

const updateAccount = async () => {
  if (!isEmailValid.value) {
    message.value = 'Please enter a valid email address';
    messageType.value = 'error';
    return;
  }

  try {
    const accountData = {
      name: name.value,
      phone: phone.value,
      email: email.value,
      notificationPreferences: notificationPreferences.value,
    };
    await store.dispatch('updateAccount', accountData);

    if (currentPassword.value && newPassword.value) {
      await api.put('/account/change-password', {
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
      }, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        },
      });
    }

    message.value = 'Account updated successfully';
    messageType.value = 'success';
  } catch (error) {
    console.error('Update failed:', error.message);
    message.value = 'Update failed';
    messageType.value = 'error';
  }
};

onMounted(fetchAccountData);
</script>

<style scoped>
.account-management-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.message {
  color: blue;
  margin-top: 10px;
}

.message-success {
  color: green;
}

.message-error {
  color: red;
}

.invalid {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

input, select, textarea {
  font-size: 16px; /* Minimum font size to prevent zoom on iOS */
}

.vue-tel-input input {
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  input, select, textarea, button {
    font-size: 16px;
  }
  
  label {
    font-size: 16px;
  }
  
  .error-message {
    font-size: 14px;
  }
}
</style>
