<template>
  <div class="login-page">
    <Header />
    <main>
      <h1>Login</h1>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="email">Email</label>
          <input v-model="email" type="email" id="email" placeholder="Email" required />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input v-model="password" type="password" id="password" placeholder="Password" required />
        </div>
        <button type="submit">Login</button>
        <button type="button" @click="toggleResetPassword" class="register-button">Reset Password</button>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </form>

      <!-- Reset Password Modal -->
      <div v-if="showResetPassword" class="modal-overlay" @click.self="toggleResetPassword">
        <div class="modal-content">
          <h2>Reset Password</h2>
          <form @submit.prevent="resetPassword" class="reset-password-form">
            <div class="form-group">
              <label for="reset-email">Email</label>
              <input v-model="resetEmail" type="email" id="reset-email" placeholder="Enter your email" required />
            </div>
            <button type="submit">Send Reset Email</button>
          </form>
          <button class="close-button" @click="toggleResetPassword">Close</button>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import api from '../axios'; // Assuming you have an axios instance set up
import Header from '../components/HeaderStd.vue';
import Footer from '../components/FooterStd.vue';

const email = ref('');
const password = ref('');
const errorMessage = ref('');
const resetEmail = ref('');
const showResetPassword = ref(false);

const store = useStore();
const router = useRouter();

const login = async () => {
  try {
    errorMessage.value = ''; // Clear previous error messages
    await store.dispatch('login', { email: email.value, password: password.value });

    // Assuming the user role is returned in the response or stored in the Vuex store
    const userRole = store.state.user.accessRole;

    // Route based on user role
    if (userRole === 'admin') {
      router.push('/shift-overview');
    } else if (userRole === 'worker') {
      router.push('/worker/overview');
    } else if (userRole === 'hospital') {
      router.push('/hospital');
    } else {
      throw new Error('Invalid user role');
    }
  } catch (error) {
    console.error('Login failed:', error.message);
    errorMessage.value = 'Wrong user or password';
  }
};

const toggleResetPassword = () => {
  showResetPassword.value = !showResetPassword.value;
};

const resetPassword = async () => {
  try {
    errorMessage.value = ''; // Clear previous error messages
    await api.post('/auth/reset-password', { email: resetEmail.value });
    errorMessage.value = 'Password reset email sent!';
  } catch (error) {
    console.error('Reset password failed:', error.message);
    errorMessage.value = 'Error sending password reset email.';
  }
};
</script>

<style scoped>
.error-message {
  color: red;
  margin-top: 10px;
}

.form-group {
  margin-bottom: 15px;
}

input {
  font-size: 16px; /* Set font size to prevent zoom on mobile */
}

button {
  margin-top: 5px; /* Add space between buttons */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-button {
  margin-top: 10px;
}
</style>
