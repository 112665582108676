<template>
  <div class="admin-view">
    <Header />
    <main>
      <h1>Admin Dashboard</h1>

      <!-- Manage Hospitals Section -->
      <section class="manage-hospitals">
        <h2>Manage Hospitals</h2>
        <button @click="showCreateHospitalModal = true">Create New Hospital</button>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hospital in hospitals" :key="hospital.id">
              <td>{{ hospital.name }}</td>
              <td>{{ hospital.address }}</td>
              <td>
                <button @click="editHospital(hospital)">Edit</button>
                <button @click="deleteHospital(hospital.id)">Delete</button>
                <button @click="editHospitalUsers(hospital)">Edit Users</button>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <!-- Manage Notification Roles Section -->
      <section class="manage-roles">
        <h2>Manage Notification Roles</h2>
        <div class="add-role-container">
          <input v-model="newRole" placeholder="Neue Rolle hinzufügen" />
          <button @click="addRole">Add Role</button>
        </div>
        <ul>
          <!-- Filter out "admin" and "hospital" roles from the displayed list -->
          <li v-for="role in filteredNotificationRolesConst" :key="role" class="role-item">
            <span>{{ role }}</span>
            <button @click="removeRole(role)">Remove</button>
          </li>
        </ul>
      </section>

      <!-- Manage Users Section -->
      <section class="manage-users">
        <div class="manage-users-header">
          <h2>Manage Users</h2>
          <button @click="showCreateUserModal = true" class="create-user-button">Create New User</button>
          <div class="manage-users-controls">
            <input v-model="userSearchQuery" placeholder="Search users..." class="search-input">
          </div>
        </div>
        <div class="manage-users-table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Access Role</th>
                <th>Notification Role</th>
                <th>Hospitals</th>
                <th>Confirmed</th>
                <th>Password Setup Link</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in filteredUsers" :key="user._id">
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>
                  <select v-model="user.accessRole" @change="handleAccessRoleChange(user)">
                    <option value="admin">Admin</option>
                    <option value="hospital">Hospital</option>
                    <option value="worker">Worker</option>
                  </select>
                </td>
                <td>
                  <select v-model="user.notificationRole" @change="handleNotificationRoleChange(user)"
                    :disabled="user.accessRole === 'admin' || user.accessRole === 'hospital'">
                    <option v-for="role in filteredNotificationRoles(user.accessRole)" :key="role" :value="role">{{ role
                      }}</option>
                  </select>
                </td>
                <td>
                  <div v-for="hospital in hospitals" :key="hospital.id" class="hospital-item">
                    <label>
                      <input type="checkbox" :checked="isUserAssociatedWithHospital(user, hospital.id)"
                        @change="toggleUserHospital(user, hospital.id)" :disabled="user.accessRole !== 'hospital'"
                        :class="{ 'greyed-out': user.accessRole !== 'hospital' }"
                        :title="user.accessRole !== 'hospital' ? 'Only hospital users can be associated to a hospital' : ''">
                      {{ hospital.name }}
                    </label>
                  </div>
                </td>

                <td>{{ user.confirmed ? 'Yes' : 'No' }}</td>
                <td>
                  <button @click="sendSetupPasswordEmail(user)">Send Setup Password Email</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </main>
    <Footer />

    <!-- Create Hospital Modal -->
    <div v-if="showCreateHospitalModal" class="modal">
      <div class="modal-content">
        <h2>Create New Hospital</h2>
        <form @submit.prevent="createHospital">
          <input v-model="newHospital.name" placeholder="Hospital Name" required>
          <input v-model="newHospital.address" placeholder="Hospital Address" required>
          <button type="submit">Create</button>
          <button type="button" @click="showCreateHospitalModal = false">Cancel</button>
        </form>
      </div>
    </div>

    <!-- Edit Hospital Modal -->
    <div v-if="showEditHospitalModal" class="modal">
      <div class="modal-content">
        <h2>Edit Hospital</h2>
        <form @submit.prevent="updateHospital">
          <input v-model="editedHospital.name" placeholder="Hospital Name" required>
          <input v-model="editedHospital.address" placeholder="Hospital Address" required>
          <button type="submit">Update</button>
          <button type="button" @click="showEditHospitalModal = false">Cancel</button>
        </form>
      </div>
    </div>

    <!-- Edit Hospital Users Modal -->
    <div v-if="showEditHospitalUsersModal" class="modal">
      <div class="modal-content">
        <h2>Edit Users for {{ selectedHospital?.name }}</h2>
        <input v-model="userSearchQuery" placeholder="Search users...">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Associated</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in sortedFilteredUsers" :key="user._id">
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <input type="checkbox" :checked="isUserAssociatedWithHospital(user, selectedHospital?.id)"
                  @change="toggleUserHospital(user, selectedHospital?.id)" :disabled="user.accessRole !== 'hospital'"
                  :class="{ 'greyed-out': user.accessRole !== 'hospital' }"
                  :title="user.accessRole !== 'hospital' ? 'Only hospital users can be associated to a hospital' : ''">
              </td>
            </tr>
          </tbody>
        </table>
        <button @click="closeEditHospitalUsersModal">OK</button>
      </div>
    </div>

    <!-- Create User Modal -->
    <div v-if="showCreateUserModal" class="modal">
      <div class="modal-content">
        <h2>Create New User</h2>
        <form @submit.prevent="createUser">
          <input v-model="newUser.name" placeholder="User Name" required>
          <input v-model="newUser.email" placeholder="User Email" required>
          <select v-model="newUser.accessRole" required @change="handleNewUserAccessRoleChange">
            <option value="admin">Admin</option>
            <option value="hospital">Hospital</option>
            <option value="worker">Worker</option>
          </select>
          <select v-model="newUser.notificationRole" required
            :disabled="newUser.accessRole === 'admin' || newUser.accessRole === 'hospital'">
            <option v-for="role in filteredNotificationRoles(newUser.accessRole)" :key="role" :value="role">
              {{ role }}
            </option>
          </select>
          <button type="submit">Create</button>
          <button type="button" @click="showCreateUserModal = false">Cancel</button>
        </form>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import Header from '../components/HeaderStd.vue';
import Footer from '../components/FooterStd.vue';
import api from '../axios';

const hospitals = ref([]);
const showCreateHospitalModal = ref(false);
const showEditHospitalModal = ref(false);
const showEditHospitalUsersModal = ref(false);
const showCreateUserModal = ref(false);
const newHospital = ref({ name: '', address: '' });
const editedHospital = ref({ id: null, name: '', address: '' });
const selectedHospital = ref(null);
const users = ref([]);
const userSearchQuery = ref('');
const tempUserSelections = ref([]);
const notificationRoles = ref([]);
const newUser = ref({ name: '', email: '', accessRole: 'worker', notificationRole: '' });
const errorMessage = ref('');
const newRole = ref('');

onMounted(async () => {
  await fetchHospitals();
  await fetchUsers();
  await fetchNotificationRoles();
});

const filteredNotificationRolesConst = computed(() => {
  return notificationRoles.value.filter(role => role !== 'admin' && role !== 'hospital');
});

function filteredNotificationRoles(accessRole) {
  if (accessRole === 'worker') {
    return notificationRoles.value.filter(role => role !== 'admin' && role !== 'hospital');
  }
  return notificationRoles.value;
}

watch(users, (newUsers) => {
  newUsers.forEach((user) => {
    if (user.accessRole === 'admin') {
      user.notificationRole = 'admin';
    } else if (user.accessRole === 'hospital') {
      user.notificationRole = 'hospital';
    }
  });
});

const handleNewUserAccessRoleChange = () => {
  if (newUser.value.accessRole === 'admin') {
    newUser.value.notificationRole = 'admin';
  } else if (newUser.value.accessRole === 'hospital') {
    newUser.value.notificationRole = 'hospital';
  } else {
    newUser.value.notificationRole = '';
  }
};

function handleAccessRoleChange(user) {
  if (user.accessRole === 'admin') {
    user.notificationRole = 'admin';
  } else if (user.accessRole === 'hospital') {
    user.notificationRole = 'hospital';
  } else if (user.accessRole === 'worker' && (user.notificationRole === 'admin' || user.notificationRole === 'hospital')) {
    user.notificationRole = ''; // Reset to empty if switching from admin/hospital to worker
  }
  saveUserAccessRole(user);
}

async function fetchHospitals() {
  try {
    const response = await api.get('/admin/hospitals');
    hospitals.value = response.data.map(hospital => ({
      id: hospital._id,
      name: hospital.name,
      address: hospital.address,
    }));
  } catch (error) {
    console.error('Error fetching hospitals:', error);
  }
}

async function fetchNotificationRoles() {
  try {
    const response = await api.get('/users/roles');
    // Filter out "hospital" and "admin" roles
    notificationRoles.value = response.data;
  } catch (error) {
    console.error('Error fetching notification roles:', error);
  }
}

async function addRole() {
  if (newRole.value.trim() === '') return;
  try {
    await api.post('/admin/roles', { role: newRole.value });
    notificationRoles.value.push(newRole.value);
    newRole.value = '';
  } catch (error) {
    console.error('Error adding role:', error);
  }
}

async function removeRole(role) {
  try {
    await api.delete(`/admin/roles/${role}`);
    notificationRoles.value = notificationRoles.value.filter(r => r !== role);
  } catch (error) {
    console.error('Error removing role:', error);
  }
}

async function createHospital() {
  try {
    await api.post('/admin/hospitals', newHospital.value);
    await fetchHospitals();
    showCreateHospitalModal.value = false;
    newHospital.value = { name: '', address: '' };
  } catch (error) {
    console.error('Error creating hospital:', error);
  }
}

function editHospital(hospital) {
  editedHospital.value = { ...hospital };
  showEditHospitalModal.value = true;
}

async function updateHospital() {
  try {
    await api.put(`/admin/hospitals/${editedHospital.value.id}`, editedHospital.value);
    await fetchHospitals();
    showEditHospitalModal.value = false;
  } catch (error) {
    console.error('Error updating hospital:', error);
  }
}

async function deleteHospital(id) {
  if (confirm('Are you sure you want to delete this hospital?')) {
    try {
      await api.delete(`/admin/hospitals/${id}`);
      await fetchHospitals();
    } catch (error) {
      console.error('Error deleting hospital:', error);
    }
  }
}

async function sendSetupPasswordEmail(user) {
  try {
    await api.post('/admin/send-setup-password-email', {
      email: user.email,
    });
    alert('Setup password email sent successfully.');
  } catch (error) {
    console.error('Error sending setup password email:', error);
    alert('Error sending setup password email.');
  }
}

async function fetchUsers() {
  try {
    const response = await api.get('/admin/users');
    users.value = response.data.map(user => ({
      ...user,
      hospitals: user.hospitals.map(hospital => hospital._id),
      confirmed: user.confirmed,
      passwordSetupLink: `/setup-password?token=${user.passwordSetupToken}`
    }));
  } catch (error) {
    console.error('Error fetching users:', error);
  }
}

function editHospitalUsers(hospital) {
  selectedHospital.value = hospital;
  tempUserSelections.value = users.value.map(user => ({
    ...user,
    hospitals: [...user.hospitals],
  }));
  showEditHospitalUsersModal.value = true;
}

function closeEditHospitalUsersModal() {
  showEditHospitalUsersModal.value = false;
}

const filteredUsers = computed(() => {
  return users.value.filter(user => {
    const searchQuery = userSearchQuery.value.toLowerCase();
    const userHospitals = user.hospitals.map(hospitalId => {
      const hospital = hospitals.value.find(h => h.id === hospitalId);
      return hospital ? hospital.name.toLowerCase() : '';
    }).join(' ');

    return (
      user.name?.toLowerCase().includes(searchQuery) ||
      user.email?.toLowerCase().includes(searchQuery) ||
      user.accessRoles?.toLowerCase().includes(searchQuery) ||
      userHospitals.includes(searchQuery)
    );
  });
});

const sortedFilteredUsers = computed(() => {
  if (!selectedHospital.value) return [];
  return [...filteredUsers.value]
    .filter(user => user.accessRole === 'hospital')
    .sort((a, b) => {
      const aAssociated = isUserAssociatedWithHospital(a, selectedHospital.value.id);
      const bAssociated = isUserAssociatedWithHospital(b, selectedHospital.value.id);
      if (aAssociated === bAssociated) return 0;
      return aAssociated ? -1 : 1;
    });
});

function isUserAssociatedWithHospital(user, hospitalId) {
  return user.hospitals && user.hospitals.includes(hospitalId);
}

async function toggleUserHospital(user, hospitalId) {
  if (!user.hospitals) {
    user.hospitals = [];
  }
  const index = user.hospitals.indexOf(hospitalId);
  if (index === -1) {
    user.hospitals.push(hospitalId);
  } else {
    user.hospitals.splice(index, 1);
  }

  try {
    await api.put(`/admin/users/${user._id}/hospitals`, { hospitals: user.hospitals });
  } catch (error) {
    console.error('Error updating user hospitals:', error);
  }
}

async function saveUserAccessRole(user) {
  try {
    await api.put(`/users/${user._id}/accessRole`, { accessRole: user.accessRole });
  } catch (error) {
    console.error('Error saving user accessRole:', error);
  }
}

async function saveUserNotificationRole(user) {
  try {
    await api.put(`/users/${user._id}/notificationRole`, { notificationRole: user.notificationRole });
  } catch (error) {
    console.error('Error saving user notificationRole:', error);
  }
}

function handleNotificationRoleChange(user) {
  saveUserNotificationRole(user);
}

function validateEmail(email) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

async function createUser() {
  if (!newUser.value.name || !newUser.value.email || !newUser.value.accessRole || !newUser.value.notificationRole) {
    errorMessage.value = 'All fields are required.';
    return;
  }

  if (!validateEmail(newUser.value.email)) {
    errorMessage.value = 'Invalid email address.';
    return;
  }

  try {
    // Convert email to lowercase before sending to the backend
    const userPayload = { ...newUser.value, email: newUser.value.email.toLowerCase() };

    const response = await api.post('/admin/users', userPayload);

    // Extract the user from the response
    const { user } = response.data;

    // Construct the password setup link using the token from the user object
    user.passwordSetupLink = `https://app.medica24.ch/setup-password?token=${user.passwordSetupToken}`;

    // Add the new user to the users list
    users.value.push(user);

    // Reset the modal and form fields
    showCreateUserModal.value = false;
    newUser.value = { name: '', email: '', accessRole: 'worker', notificationRole: '' };
    errorMessage.value = '';
  } catch (error) {
    console.error('Error creating user:', error);
    errorMessage.value = 'Error creating user.';
  }

}
</script>

<style scoped>
.admin-view {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%;
  box-sizing: border-box;
}

h1,
h2 {
  margin-bottom: 1rem;
}

.manage-users table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  table-layout: auto;
  /* Allow table to adjust based on content */
}


.manage-users th,
.manage-users td {
  min-width: 150px;
  /* Adjust the minimum width as needed */
  max-width: 250px;
  /* Set a maximum width for each column */
  overflow: hidden;
  text-overflow: ellipsis;
}

.hospital-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  /* Prevent line breaks within the label */
}

.manage-users th {
  background-color: #f2f2f2;
}


.manage-users select {
  width: 100%;
  /* Make select inputs take full width of the cell */
  max-width: 100%;
  /* Ensure they do not exceed the cell width */
  box-sizing: border-box;
  /* Include padding and border in the element's total width */
}

.manage-hospitals,
.manage-users {
  width: 100%;
  margin-bottom: 2rem;
}

.create-user-button {
  margin-bottom: 0.5rem;
}

.manage-users-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  padding: 0.5rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional shadow for better visibility */
}

.manage-users-table-container {
  overflow-x: auto;
  /* Enable horizontal scrolling */
}

.manage-users-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  table-layout: fixed;
}

th,
td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
  word-wrap: break-word;
}

th {
  background-color: #f2f2f2;
}

button {
  margin-right: 0.5rem;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 4px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

input {
  padding: 0.5rem;
}

input,
select,
button {
  font-size: 16px;
  /* Ensure font size is at least 16px to prevent zooming */
}

.search-input {
  width: 100%;
}

select[multiple] {
  height: 100px;
}

.manage-hospitals td button {
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  /* Add space between buttons */
}

.manage-hospitals td button:last-child {
  margin-right: 0;
  marigin-bottom: 0;
  /* Remove margin from the last button to avoid extra space */
}

.greyed-out {
  opacity: 0.5;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
}

/* Specific styles for buttons in the manage roles section */
.manage-roles button {
  max-width: 150px;
  /* Set a maximum width for role management buttons */
  width: 100%;
  /* Ensure buttons take full width up to the max-width */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
}

.add-role-container button {
  max-width: 100px;
  /* Specific max-width for the add role button if needed */
}

.role-item button {
  max-width: 100px;
  /* Specific max-width for the remove button if needed */
}

.manage-roles {
  margin-top: 2rem;
  width: 100%;
  max-width: 800px;
}

.add-role-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.add-role-container input {
  flex: 1;
  margin-right: 0.5rem;
}

.role-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  table {
    font-size: 0.9rem;
  }

  th,
  td {
    padding: 0.25rem;
  }

  .modal-content {
    width: 100%;
    padding: 1rem;
  }
}
</style>
