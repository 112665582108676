<template>
  <div class="setup-password-view">
    <h1>Set Up Your Password</h1>
    <form @submit.prevent="setupPassword">
      <input v-model="password" type="password" placeholder="New Password" required />
      <input v-model="confirmPassword" type="password" placeholder="Confirm Password" required />
      <button type="submit">Submit</button>
    </form>
    <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    <p v-if="successMessage" class="success">{{ successMessage }}</p>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import api from '../axios';

const route = useRoute();
const router = useRouter();
const token = route.query.token;

const password = ref('');
const confirmPassword = ref('');
const errorMessage = ref('');
const successMessage = ref('');

async function setupPassword() {
  if (password.value !== confirmPassword.value) {
    errorMessage.value = 'Passwords do not match.';
    return;
  }

  try {
    // Removed 'const response ='
    await api.post('/auth/setup-password', {
      token,
      password: password.value,
    });
    successMessage.value = 'Password set up successfully!';
    errorMessage.value = '';
    
    // Redirect to the start page after 3 seconds
    setTimeout(() => {
      router.push('/');
    }, 3000);
  } catch (error) {
    errorMessage.value = error.response?.data?.message || 'Error setting up password.';
  }
}
</script>

<style scoped>
.setup-password-view {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
}

.error {
  color: red;
}

.success {
  color: green;
}
</style>