<template>
  <div class="shift-overview-page">
    <Header />
    <main>
      <h1>Übersicht aller Schichten</h1>
      <div class="filters">
        <label>
          Von:
          <input type="date" v-model="startDate" @change="fetchAllShifts" />
        </label>
        <label>
          Bis:
          <input type="date" v-model="endDate" @change="fetchAllShifts" />
        </label>
        <input type="text" v-model="searchQuery" placeholder="Search..." />
      </div>
      <div class="shift-table-container">
        <table class="shift-table">
          <thead>
            <tr>
              <th @click="sortBy('hospital.name')" class="sortable">
                Krankenhaus
                <i :class="getSortIcon('hospital.name')"></i>
              </th>
              <th @click="sortBy('status')" class="sortable">
                Status
                <i :class="getSortIcon('status')"></i>
              </th>
              <th @click="sortBy('userName')" class="sortable">
                Arbeiter
                <i :class="getSortIcon('userName')"></i>
              </th>
              <th @click="sortBy('start')" class="sortable">
                Start Datum & Zeit
                <i :class="getSortIcon('start')"></i>
              </th>
              <th @click="sortBy('end')" class="sortable">
                Ende Datum & Zeit
                <i :class="getSortIcon('end')"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="shift in sortedShifts" :key="shift._id">
              <td>{{ shift.hospital.name }}</td>
              <td>{{ shift.status }}</td>
              <td>{{ shift.user ? shift.user.name : 'Nicht zugewiesen' }}</td>
              <td>{{ formatDateTime(shift.start) }}</td>
              <td>{{ formatDateTime(shift.end) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import Header from '../components/HeaderStd.vue';
import Footer from '../components/FooterStd.vue';
import api from '../axios';

const shifts = ref([]);
const searchQuery = ref('');
const startDate = ref(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substr(0, 10));
const endDate = ref(new Date(new Date().setDate(new Date().getDate() + 14)).toISOString().substr(0, 10));
const currentSort = ref('start');
const currentSortDir = ref('asc');

const fetchAllShifts = async () => {
  try {
    const response = await api.get('/shifts/all', {
      params: {
        startDate: startDate.value,
        endDate: endDate.value,
      },
    });
    shifts.value = response.data;
  } catch (error) {
    console.error('Error fetching all shifts:', error);
  }
};

const formatDateTime = (dateTime) => {
  const options = { weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
  return new Intl.DateTimeFormat('de-DE', options).format(new Date(dateTime));
};

const sortBy = (field) => {
  if (field === currentSort.value) {
    currentSortDir.value = currentSortDir.value === 'asc' ? 'desc' : 'asc';
  } else {
    currentSort.value = field;
    currentSortDir.value = 'asc';
  }
};

const getSortIcon = (field) => {
  if (field === currentSort.value) {
    return {
      'fas fa-sort-up': currentSortDir.value === 'asc',
      'fas fa-sort-down': currentSortDir.value === 'desc'
    };
  }
  return 'fas fa-sort';
};

const filteredShifts = computed(() => {
  return shifts.value.filter(shift => {
    const search = searchQuery.value.toLowerCase();
    return (
      shift.hospital.name.toLowerCase().includes(search) ||
      shift.status.toLowerCase().includes(search) ||
      (shift.user && shift.user.name.toLowerCase().includes(search))
    );
  });
});

const sortedShifts = computed(() => {
  return [...filteredShifts.value].sort((a, b) => {
    let aValue = a[currentSort.value];
    let bValue = b[currentSort.value];

    // Handle nested hospital name
    if (currentSort.value === 'hospital.name') {
      aValue = a.hospital.name;
      bValue = b.hospital.name;
    }

    // Handle user name
    if (currentSort.value === 'userName') {
      aValue = a.user ? a.user.name : '';
      bValue = b.user ? b.user.name : '';
    }

    // Handle date fields
    if (['start', 'end'].includes(currentSort.value)) {
      aValue = new Date(aValue);
      bValue = new Date(bValue);
    }

    if (aValue < bValue) return currentSortDir.value === 'asc' ? -1 : 1;
    if (aValue > bValue) return currentSortDir.value === 'asc' ? 1 : -1;
    return 0;
  });
});

onMounted(() => {
  fetchAllShifts();
});
</script>

<style scoped>
.shift-overview-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

main {
  padding: 1rem;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  align-items: flex-end;
}

.filters label {
  flex: 1 1 100%;
}

.filters input[type="date"],
.filters input[type="text"] {
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  height: 2.5rem;
  font-size: 1rem;
}

.shift-table-container {
  overflow-x: auto;
  width: 100%;
}

.shift-table {
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;
  margin-top: 1rem;
}

th,
td {
  border: 1px solid #ddd;
  padding: 0.5rem;
  text-align: left;
  word-wrap: break-word;
  min-width: 150px;
}

th {
  background-color: #f2f2f2;
}

.sortable {
  cursor: pointer;
  user-select: none;
  position: relative;
}

.sortable:hover {
  background-color: #e9e9e9;
}

.sortable i {
  margin-left: 5px;
  font-size: 0.8em;
}

@media (min-width: 600px) {
  .filters {
    flex-wrap: nowrap;
  }

  .filters label {
    flex: 1;
  }
}
</style>
