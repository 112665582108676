import axios from 'axios';
import store from '../store';
import { jwtDecode } from 'jwt-decode'; // Corrected import statement
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

const refreshTokenInterval = 3600 * 1000; // Check for token refresh every hour

const refreshToken = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/auth/refresh-token`, {
      token: store.state.token,
    });
    store.commit('setToken', response.data.token);
    const user = jwtDecode(response.data.token);
    store.commit('setUser', user);
  } catch (error) {
    console.error('Error refreshing token:', error);
    store.commit('logout'); // Optionally log out the user on error
    redirectToLogin();
  }
};

const redirectToLogin = () => {
  window.location.href = '/login';
};

let refreshTimeout;

const resetRefreshTimeout = () => {
  clearTimeout(refreshTimeout);
  refreshTimeout = setTimeout(refreshToken, refreshTokenInterval);
};

['click', 'mousemove', 'keypress'].forEach((event) => {
  window.addEventListener(event, resetRefreshTimeout);
});

// Add visibility change event listener
const checkAuthTokenOnVisibilityChange = () => {
  if (document.visibilityState === 'visible') {
    refreshToken(); // Check and refresh token when the page becomes visible
  }
};

document.addEventListener('visibilitychange', checkAuthTokenOnVisibilityChange);

// Initialize the timeout on load
resetRefreshTimeout();

export default {
  install: (app) => {
    app.mixin({
      methods: {
        myGlobalMethod() {
          console.log('This is a global method');
        }
      }
    });
  }
};
